<template>
<magic-moment :InteressenMost5="most5" :NahrungMost5="most5" :GesundheitMost5="most5"
              :UnterhaltungMost5="most5" :ReisenMost5="most5"
              :WissenMost5="most5" :ShoppingMost5="most5"></magic-moment>
</template>

<script>
/* eslint-disable */
import MagicMoment from '@/components/magicMoment';
export default {
  name: 'Magic',
  components: { MagicMoment },
  data() {
    return {
      most5: [  'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas','Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan','Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas','Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan',  'Fajitas', 'rezept', 'Gemüse', 'Curry','Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger', 'Curry', 'vegan', 'Fajitas', 'rezept', 'Gemüse', 'Kräuter', 'SwigKitchen', 'Burger'],

    };
  },
  mounted() {
    console.log(this.most5.length);
  }
};
</script>

<style scoped>
</style>
